<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useToggle } from '@vueuse/core'
import { useQuoteStore } from '@/stores/quote'
import {
  AlmostThereUKSchema,
  type AlmostThereUKSchemaErrorsType,
  AlmostThereEUSchema,
  type AlmostThereEUSchemaErrorsType
} from '@/stores/quote.schema'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import QuoteFormFieldErrors from '@/components/Quote/QuoteFormFieldErrors.vue'
import BackButton from '@/components/Quote/BackButton.vue'
import IconInformation from '@/components/icons/IconInformation.vue'
import FieldCombobox from '@/components/Quote/FieldCombobox.vue'
import LoadingText from '@/components/LoadingText.vue'
import FormSpinner from '@/components/Quote/FormSpinner.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'
import {
  isZixtyAxiosErrorCheck,
  getFullQuote,
  isOccupationBusinessOk,
  type ApiErrorResponse
} from '@/utils/api'
import FieldDriverLicenceNumber from '@/components/Quote/FieldDriverLicenceNumber.vue'
import FieldDriverEULicenceNumber from '@/components/Quote/FieldDriverEULicenceNumber.vue'
import ToolTip from '@/components/Tooltip.vue'
import Modal from '@/components/Modal.vue'

const [occupationHelpToggleValue, occupationHelpToggle] = useToggle()
const [businessTypeHelpToggleValue, businessTypeHelpToggle] = useToggle()

const $route = useRoute()
const $router = useRouter()

const isAggregator = computed(() => $route.params?.journey === 'aggregator')

useHead({
  title: isAggregator.value ? `We just need a few more details...` : `Almost there`
})

const quote = useQuoteStore()

const inAjaxCall = ref<boolean>(false)

const errorsClient = ref<
  AlmostThereUKSchemaErrorsType | AlmostThereEUSchemaErrorsType | undefined
>()

const errorsServer = ref<ApiErrorResponse | undefined>()

/* get the choices list */
const createEnumerationMappingHelper = (
  arr?: Array<{ code: string; description: string }>,
  selectedCode?: string
) => {
  return (arr || []).map(({ code, description }) => ({
    value: code,
    label: description,
    selected: !!(selectedCode && code === selectedCode)
  }))
}

const genderChoices = ref(
  createEnumerationMappingHelper(
    [
      {
        code: 'male',
        description: 'Male'
      },
      {
        code: 'female',
        description: 'Female'
      }
    ],
    quote.inputs.gender!
  )
)

const occupationChoices = ref(
  createEnumerationMappingHelper(
    quote.responses.quickquote?.enumerations?.Occupation,
    quote.inputs.occupation
  )
)

const businessTypeChoices = ref(
  createEnumerationMappingHelper(
    quote.responses.quickquote?.enumerations?.Employer,
    quote.inputs.business_type
  )
)

const reasonForCoverChoices = ref(
  createEnumerationMappingHelper(
    quote.responses.quickquote?.enumerations?.CoverReason,
    quote.inputs.reason_for_cover
  )
)

const reasonForInsuringOwnVehicleChoices = ref(
  createEnumerationMappingHelper(
    quote.responses.quickquote?.enumerations?.CoverReason3,
    quote.inputs.reason_for_cover_own_vehicle
  )
)

const relationToLenderChoices = ref(
  createEnumerationMappingHelper(
    quote.responses.quickquote?.enumerations?.CoverReason2,
    quote.inputs.relation
  )
)

// reset the options relation to reason for cover when reason_for_cover changes
watch(
  () => quote.inputs?.reason_for_cover,
  (nv, ov) => {
    if (nv !== ov) {
      quote.inputs.reason_for_cover_own_vehicle = ''
      quote.inputs.relation = ''
      quote.inputs.lender_first_name = ''
      quote.inputs.lender_last_name = ''
    }
  }
)

const motModal = ref<InstanceType<typeof Modal> | null>(null)

const isOccupationBusinessOkModal = ref<InstanceType<typeof Modal> | null>(null)

const showIsOccupationBusiness = ref<boolean>(false)

const isOccupationBusinessValid = ref<boolean>()

const hideOccupationField = computed((): boolean => {
  if (!isAggregator.value) return false
  if (isOccupationBusinessValid.value === false) return false
  if (quote.inputs.is_occupation_business_ok_shown === true) return false
  if (quote.responses.aggregator?.revisions) return false
  return true
})

const hideBusinessTypeField = computed((): boolean => {
  if (!isAggregator.value) return false
  if (isOccupationBusinessValid.value === false) return false
  if (quote.inputs.is_occupation_business_ok_shown === true) return false
  if (quote.responses.aggregator?.revisions) return false
  return true
})

const isOccupationBusinessOkModalCloseModal = () => {
  setTimeout(() => {
    isOccupationBusinessOkModal.value?.close()
  }, 0)
}

const isOccupationBusinessOkModalCancel = (e?: Event) => {
  // reset and close the modal
  showIsOccupationBusiness.value = false
  isOccupationBusinessOkModalCloseModal()
}

const isOccupationBusinessOkModalContinue = async (e?: Event) => {
  showIsOccupationBusiness.value = true
  isOccupationBusinessOkModalCloseModal()
  return await onSubmit(e)
}

const naviagteToNextPage = () => {
  // open the modal when "mot_valid" is 'null' or 'false'
  // worth noting that the "mot_valid" property is only returned for the direct fullquote response
  if (
    quote.responses.fullquote?.mot_valid === false ||
    quote.responses.fullquote?.mot_valid === null
  ) {
    // check whether we have shown the modal for this vehicle registration
    if (quote.inputs?.registration_number_mot_valid_shown !== quote.inputs?.registration_number) {
      quote.inputs.registration_number_mot_valid_shown = quote.inputs?.registration_number
      return motModal.value?.open()
    }
  }

  // close the modal
  motModal.value?.close()
  isOccupationBusinessOkModalCloseModal()
  showIsOccupationBusiness.value = false

  // go to the next page
  if (isAggregator.value && !quote.responses.aggregator?.revisions) {
    $router.push({
      name: 'quote.upgrade'
    })
  } else {
    $router.push({
      name: 'quote.quote'
    })
  }
}

const onSubmit = async (e?: Event) => {
  if (inAjaxCall.value) return
  inAjaxCall.value = true

  quote.helpers.recordWebEvent(
    quote.responses.aggregator?.correlation_id
      ? 'ALMOST_THERE_POST_SUBMITTED'
      : 'ALMOST_THERE_PRE_SUBMITTED'
  )

  // process the driving licence, we want to make it uppercase for the user's input
  // then we want to copy the value the user entered and replace spaces and take
  // the first 16 characters and validate against that
  quote.inputs.driving_licence_number_ui = (quote.inputs?.driving_licence_number_ui || '')
    .slice()
    .toUpperCase()

  if (quote.inputs.dlntype === 'LICENCE_TYPE_FULL') {
    quote.inputs.driving_licence_number = quote.inputs.driving_licence_number_ui
      .replace(/\s/g, '')
      .slice(0, 16)
  } else if (quote.inputs.dlntype === 'LICENCE_TYPE_EU') {
    quote.inputs.driving_licence_number = quote.inputs.driving_licence_number_ui
  }

  // process the phone number, we want to strip out the spaces from the user's input
  quote.inputs.phone_number = (quote.inputs.phone_number_ui || '').slice().replace(/\s/g, '')

  // validate
  let safeParsed

  if (quote.inputs.dlntype === 'LICENCE_TYPE_FULL') {
    safeParsed = AlmostThereUKSchema.safeParse({ ...quote.inputs })
  } else if (quote.inputs.dlntype === 'LICENCE_TYPE_EU') {
    safeParsed = AlmostThereEUSchema.safeParse({ ...quote.inputs, gender: null })
  }

  errorsClient.value = safeParsed && !safeParsed.success ? safeParsed.error.flatten() : undefined
  errorsServer.value = undefined

  if (safeParsed?.success) {
    try {
      // check if the request is okay unless it's shown and the user is skipping
      if (!showIsOccupationBusiness.value) {
        const isOkayPreCheck = await isOccupationBusinessOk({
          correlation_id: quote.responses.correlation_id || '',
          occupation: safeParsed.data.occupation || '',
          business_type: safeParsed.data.business_type || ''
        })

        isOccupationBusinessValid.value = Boolean(isOkayPreCheck.data.is_occupation_business_ok)

        // open the modal when 'is_occupation_business_ok_modal' is 'false'
        if (isOccupationBusinessValid.value === false) {
          inAjaxCall.value = false
          showIsOccupationBusiness.value = true
          quote.inputs.is_occupation_business_ok_shown = true
          return isOccupationBusinessOkModal.value?.open()
        }
      }

      // get the fullquote
      const response = await getFullQuote({
        correlation_id: quote.responses.correlation_id || '',
        driving_licence_number: safeParsed.data.driving_licence_number || '',
        phone_number: '+44' + (safeParsed.data.phone_number || '').replace(/^0/, ''),
        reason_for_cover: safeParsed.data.reason_for_cover || '',
        reason_for_cover_own_vehicle: safeParsed.data.reason_for_cover_own_vehicle || null,
        relation: safeParsed.data.relation || null,
        occupation: safeParsed.data.occupation || '',
        business_type: safeParsed.data.business_type || '',
        lender_first_name: safeParsed.data.lender_first_name || '',
        lender_last_name: safeParsed.data.lender_last_name || '',
        gender: safeParsed.data?.gender || null
      })

      quote.responses.quote_expires_at = response?.headers['x-expiry-date'] || ''

      const data = response.data

      quote.responses.fullquote = {
        ...data
      }

      naviagteToNextPage()
    } catch (error) {
      errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
    }
  }

  inAjaxCall.value = false
}

const onAggregatorJourneyMounted = async () => {
  // exit early if not aggregator
  if (!isAggregator.value) return

  if (
    quote.responses.aggregator?.driving_licence_number_is_ok === false ||
    quote.responses.aggregator?.phone_number_is_mobile === false
  ) {
    // generic error
    errorsClient.value = {
      formErrors: errorsClient.value?.formErrors || [],
      fieldErrors: errorsClient.value?.fieldErrors || {}
    }

    // handle cases where the server thinks the dln is incorrect
    if (
      quote.responses.aggregator?.driving_licence_number_is_ok === false &&
      quote.responses.aggregator?.risk?.driving_licence_number !== '' &&
      quote.responses.aggregator?.risk?.driving_licence_number ===
        quote.inputs.driving_licence_number_ui
    ) {
      errorsClient.value.fieldErrors.driving_licence_number = [
        `Please enter a valid UK DVLA issued driving licence`
      ]
    }

    // handle cases where the server thinks the phone number is incorrect
    if (
      quote.responses.aggregator?.phone_number_is_mobile === false &&
      quote.responses.aggregator?.risk?.phone_number !== '' &&
      quote.responses.aggregator?.risk?.phone_number === quote.inputs.phone_number_ui
    ) {
      errorsClient.value.fieldErrors.phone_number = [`Please enter a valid UK mobile number`]
    }
  }
}

onMounted(async () => {
  quote.helpers.recordWebEvent(
    quote.responses.aggregator?.correlation_id ? 'ALMOST_THERE_POST' : 'ALMOST_THERE_PRE'
  )

  // As discussed don't show the error messages on load, let the user click Continue
  // await onAggregatorJourneyMounted()
})
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto lg:w-5/12 md:max-w-sm">
      <div class="py-3 md:py-6 flex justify-center items-center text-center">
        <QuoteFormTitle>
          <span v-if="isAggregator">We just need a few more details...</span>
          <span v-else>Almost there</span>
        </QuoteFormTitle>
      </div>
      <FormServerErrors v-if="errorsServer" :error="errorsServer" />
      <div class="w-full">
        <form
          id="form-quote-almost-there"
          @submit.prevent="onSubmit"
          novalidate
          :class="{
            'form23a-loading': inAjaxCall
          }"
        >
          <FormSpinner v-if="inAjaxCall" />

          <div
            v-if="quote.inputs.dlntype === 'LICENCE_TYPE_FULL'"
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.gender
            }"
            id="field-group-dlngender"
          >
            <label class="form23a-label flex" for="dlngender">
              Gender
              <span class="mx-0.5"></span>
              <ToolTip
                content="We need to ask for your gender in order to prepopulate your driving licence number. The gender that you provide must match the record held by the DVLA. We understand that you may choose to identify differently."
              />
            </label>
            <div class="w-full">
              <select
                id="form-quote-almost-there--field-dlngender"
                class="data-hj-allow form23a-select w-full"
                name="dlngender"
                v-model="quote.inputs.gender"
                :class="{
                  'form23a-unselected': !quote.inputs.gender
                }"
              >
                <option :value="undefined" disabled>Please pick an option...</option>
                <option v-for="item in genderChoices" :value="item.value" :key="item.value">
                  {{ item.label }}
                </option>
              </select>
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.gender" />
          </div>

          <div>
            <div v-if="quote.inputs.dlntype === 'LICENCE_TYPE_FULL'">
              <FieldDriverLicenceNumber
                v-model="quote.inputs.driving_licence_number_ui"
                :errors-client="errorsClient"
              />
            </div>
            <div v-else-if="quote.inputs.dlntype === 'LICENCE_TYPE_EU'">
              <FieldDriverEULicenceNumber
                v-model="quote.inputs.driving_licence_number_ui"
                :errors-client="errorsClient"
              />
            </div>
          </div>

          <div
            v-if="quote.inputs.dlntype === 'LICENCE_TYPE_EU'"
            class="form23a-field-group"
            :class="{
              error: (errorsClient as AlmostThereEUSchemaErrorsType)?.fieldErrors?.driving_licence_held_for_years
            }"
            id="field-group-dln-licence-held-for-years"
          >
            <label class="form23a-label flex" for="licence-held-for-years">
              How long have you held your EU licence?
            </label>
            <div class="w-full">
              <select
                id="form-quote-almost-there--field-licence-held-for-years"
                class="data-hj-allow form23a-select w-full"
                name="licence-held-for-years"
                v-model="quote.inputs.driving_licence_held_for_years"
                :class="{
                  'form23a-unselected': !quote.inputs.driving_licence_held_for_years
                }"
              >
                <option :value="undefined" disabled>Please pick an option...</option>
                <option
                  v-for="item in [
                    {
                      value: 1,
                      label: 'Less than 3 years'
                    },
                    {
                      value: 3,
                      label: '3+ years'
                    }
                  ]"
                  :value="item.value"
                  :key="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
            <QuoteFormFieldErrors
              :errors="(errorsClient as AlmostThereEUSchemaErrorsType)?.fieldErrors?.driving_licence_held_for_years"
            />
          </div>

          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.occupation,
              hidden: hideOccupationField
            }"
            id="field-group-occupation"
          >
            <div class="flex">
              <label class="form23a-label" for="occupation"> Occupation </label>
              <button
                type="button"
                @click.prevent="($event) => occupationHelpToggle()"
                class="ml-1 -mt-1 text-[#95C11F] cursor-pointer"
              >
                <IconInformation class="h-5 w-5" />
              </button>
            </div>
            <small class="my-1 mb-2 text-[#595959]">Start typing to find a match</small>
            <div class="w-full" v-if="!!occupationChoices">
              <FieldCombobox
                id="form-quote-almost-there--field-field-occupation"
                class="data-hj-allow w-full"
                name="occupation"
                placeholder="e.g. teacher"
                no-result-message="No Occupation found. Consider changing your search."
                v-model="quote.inputs.occupation"
                :options="occupationChoices"
              />
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.occupation" />
            <div
              v-show="occupationHelpToggleValue"
              class="mt-3 text-xs text-[#595959] py-3 px-6 bg-[#F6F6F6] rounded-md"
            >
              <div class="flex justify-end -mr-3">
                <button
                  type="button"
                  class="cursor-pointer hover:text-[#95C11F] border-none focus:ring-0 focus-visible:outline-none"
                  @click.prevent="occupationHelpToggle()"
                  title="Close"
                >
                  <span aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                  <span class="sr-only">Close</span>
                </button>
              </div>
              <div class="mt-3 pb-3">
                <div>
                  <p class="mt-3">
                    We use your job, and the sector you work in, to calculate the cost of your
                    policy.
                  </p>
                </div>
                <div>
                  <p class="mt-6 font-bold">I can’t find my job</p>
                  <p class="mt-3">
                    Type some letters into the Occupation field, and we’ll list those that are the
                    closest to what you’ve typed. e.g. “teacher” would show “Teacher” as well as
                    “Piano teacher”.
                  </p>
                  <p class="mt-3">
                    If you still can't find your job, type your job title to get the closest match,
                    or chat to us.
                  </p>
                </div>
                <div>
                  <p class="mt-6 font-bold">I have more than one job</p>
                  <p class="mt-3">
                    If you have more than one job, please select the one that you spend the greatest
                    amount of time doing each week.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.business_type,
              hidden: hideBusinessTypeField
            }"
            id="field-group-business-type"
          >
            <div class="flex">
              <label class="form23a-label" for="business_sector"> Business sector </label>
              <button
                type="button"
                @click.prevent="($event) => businessTypeHelpToggle()"
                class="ml-1 -mt-1 text-[#95C11F] cursor-pointer"
              >
                <IconInformation class="h-5 w-5" />
              </button>
            </div>
            <small class="my-1 mb-2 text-[#595959]">Start typing to find a match</small>
            <div class="w-full" v-if="!!businessTypeChoices">
              <FieldCombobox
                id="form-quote-almost-there--field-field-business-sector"
                class="data-hj-allow w-full"
                name="business_sector"
                placeholder="e.g. education"
                no-result-message="No Business Sector found. Consider changing your search."
                v-model="quote.inputs.business_type"
                :options="businessTypeChoices"
              />
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.business_type" />
            <div
              v-show="businessTypeHelpToggleValue"
              class="mt-3 text-xs text-[#595959] py-3 px-6 bg-[#F6F6F6] rounded-md"
            >
              <div class="flex justify-end -mr-3">
                <button
                  type="button"
                  class="cursor-pointer hover:text-[#95C11F] border-none focus:ring-0 focus-visible:outline-none"
                  @click.prevent="businessTypeHelpToggle()"
                  title="Close"
                >
                  <span aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                  <span class="sr-only">Close</span>
                </button>
              </div>
              <div class="mt-3 pb-3">
                <div>
                  <p class="mt-3">
                    Type some letters into the Business Sector field, and we’ll list those that are
                    the closest to what you’ve typed. e.g. “market” would show “Marketing” as well
                    as “Market gardening”.
                  </p>
                </div>
                <div>
                  <p class="mt-6 font-bold">If you can’t find your Sector</p>
                  <p class="mt-3">
                    Sometimes your sector won't be listed, so you may need to find one that is
                    similar to what you do.
                  </p>
                  <p class="mt-3">
                    If you still can't find your Sector, choose the closest match, or chat to us.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form23a-field-group"
            :class="{
              error:
                !!errorsClient?.fieldErrors?.phone_number_ui ||
                !!errorsClient?.fieldErrors?.phone_number
            }"
            id="field-group-phone-number"
          >
            <label class="form23a-label" for="mobile_number"> Mobile number </label>
            <div class="w-full">
              <input
                type="text"
                name="mobile_number"
                id="form-quote-almost-there--field-field-mobile-number"
                placeholder="Enter driver's mobile number..."
                class="form23a-input w-full"
                v-model="quote.inputs.phone_number_ui"
              />
            </div>
            <QuoteFormFieldErrors
              :errors="
                errorsClient?.fieldErrors?.phone_number_ui ||
                errorsClient?.fieldErrors?.phone_number
              "
            />
          </div>
          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.reason_for_cover,
              hidden: isAggregator
            }"
            id="field-group-reason-for-cover"
          >
            <label class="form23a-label" for="reason_for_cover"> Reason for cover </label>
            <div class="w-full">
              <select
                id="form-quote-almost-there--field-reason-for-cover"
                class="data-hj-allow form23a-select w-full"
                name="reason_for_cover"
                v-model="quote.inputs.reason_for_cover"
                :class="{
                  'form23a-unselected': !quote.inputs.reason_for_cover
                }"
              >
                <option value="" disabled>Pick a reason for cover...</option>
                <option v-for="item in reasonForCoverChoices" :value="item.value" :key="item.value">
                  {{ item.label }}
                </option>
              </select>
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.reason_for_cover" />
          </div>
          <div v-if="!!quote.inputs.reason_for_cover && quote.inputs.reason_for_cover === 'IOV'">
            <div v-if="isAggregator" class="w-full flex justify-center text-sm text-[#595959] py-3">
              <div class="max-w-sm text-center">
                <p>As you’re insuring your own vehicle we need to know why:</p>
              </div>
            </div>
            <div
              class="form23a-field-group"
              :class="{
                error: !!errorsClient?.fieldErrors?.reason_for_cover_own_vehicle
              }"
              id="field-group-reason-for-cover-own-vehicle"
            >
              <label class="form23a-label" for="reason_for_insuring_own_vehicle">
                Reason for insuring own vehicle
              </label>
              <div class="w-full">
                <select
                  id="form-quote-almost-there--field-reason-for-insuring-own-vehicle"
                  class="data-hj-allow form23a-select w-full"
                  name="reason_for_insuring_own_vehicle"
                  v-model="quote.inputs.reason_for_cover_own_vehicle"
                  :class="{
                    'form23a-unselected': !quote.inputs.reason_for_cover_own_vehicle
                  }"
                >
                  <option value="" disabled>Pick a reason for insuring own vehicle...</option>
                  <option
                    v-for="item in reasonForInsuringOwnVehicleChoices"
                    :value="item.value"
                    :key="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>
              <QuoteFormFieldErrors
                :errors="errorsClient?.fieldErrors?.reason_for_cover_own_vehicle"
              />
            </div>
          </div>
          <div v-if="!!quote.inputs.reason_for_cover && quote.inputs.reason_for_cover === 'BV'">
            <div v-if="isAggregator" class="w-full flex justify-center text-sm text-[#595959] py-3">
              <div class="max-w-sm text-center">
                <p>As you’re borrowing a vehicle we need to know about the owner:</p>
              </div>
            </div>
            <div
              class="form23a-field-group"
              :class="{
                error: !!errorsClient?.fieldErrors?.relation
              }"
              id="field-group-relation"
            >
              <label class="form23a-label" for="lender_relationship">
                Relationship to lender
              </label>
              <div class="w-full">
                <select
                  id="form-quote-almost-there--field-lender-relationship"
                  class="data-hj-allow form23a-select w-full"
                  name="lender_relationship"
                  v-model="quote.inputs.relation"
                  :class="{
                    'form23a-unselected': !quote.inputs.relation
                  }"
                >
                  <option value="" disabled>Relationship to lender...</option>
                  <option
                    v-for="item in relationToLenderChoices"
                    :value="item.value"
                    :key="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>
              <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.relation" />
            </div>
            <div
              class="form23a-field-group"
              :class="{
                error: !!errorsClient?.fieldErrors?.lender_first_name
              }"
            >
              <label class="form23a-label" for="lenders_first_name"> Lender’s first name </label>
              <div class="w-full">
                <input
                  type="text"
                  name="lenders_first_name"
                  placeholder="Enter lender’s first name..."
                  id="form-quote-almost-there--field-lenders-first-name"
                  class="data-hj-suppress form23a-input w-full"
                  v-model="quote.inputs.lender_first_name"
                />
              </div>
              <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.lender_first_name" />
            </div>
            <div
              class="form23a-field-group"
              :class="{
                error: !!errorsClient?.fieldErrors?.lender_last_name
              }"
            >
              <label class="form23a-label" for="lenders_last_name"> Lender’s last name </label>
              <div class="w-full">
                <input
                  type="text"
                  name="lenders_last_name"
                  placeholder="Enter lender’s last name..."
                  id="form-quote-almost-there--field-lenders-last-name"
                  class="data-hj-suppress form23a-input w-full"
                  v-model="quote.inputs.lender_last_name"
                />
              </div>
              <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.lender_last_name" />
            </div>
          </div>
          <div
            v-if="isAggregator && !quote.responses.aggregator?.revisions"
            class="pt-6 w-full flex flex-col justify-center text-[#595959] text-center"
          >
            <h2 class="text-[#a9a9a9] text-xl md:text-2xl font-thin">Choose your extras next</h2>
          </div>
          <div class="py-3 md:py-6 w-full flex justify-center">
            <QuoteFormContinueButton
              type="submit"
              :deactivate="inAjaxCall"
              id="submit-full-quote-button"
            >
              <span v-if="inAjaxCall" class="flex items-center">
                <LoadingText text="Quoting" :dots="0" />
              </span>
              <span v-else> Continue </span>
            </QuoteFormContinueButton>
          </div>
          <BackButton
            :route-name="
              !isAggregator || !!quote.responses.aggregator?.revisions
                ? 'quote.quick-quote'
                : 'quote.quote'
            "
          />

          <Modal size="max-w-2xl" ref="motModal" :is-closable="true">
            <template v-slot:title>
              <div>No MOT found</div>
            </template>
            <template v-slot:content>
              <div>
                <div class="text-[#595959] text-sm">
                  <div class="my-3">
                    <p>We cannot find a valid MOT for this vehicle. The vehicle either:</p>
                  </div>
                  <div class="ml-8 mt-0 mb-3">
                    <ol class="list-decimal">
                      <li class="my-3">
                        <p>
                          Has recently passed an MOT, but the systems we check haven't been updated.
                          A Zixty policy covers this; or
                        </p>
                      </li>
                      <li class="my-3">
                        <p>
                          Has no MOT, has no dangerous defects, and is being used to drive directly
                          only to a pre-booked MOT or garage. A Zixty policy covers this; or
                        </p>
                      </li>
                      <li class="my-3">
                        <p>
                          Has no MOT, and will be driven other than to a pre-booked MOT or garage.
                          This is illegal and your policy could be invalid.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    class="bg-[#95C11F] text-white font-medium p-2 px-6 rounded-full"
                    @click.prevent="naviagteToNextPage"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </template>
          </Modal>

          <Modal
            size="max-w-2xl"
            ref="isOccupationBusinessOkModal"
            :is-closable="true"
            @close="isOccupationBusinessOkModalCancel"
          >
            <template v-slot:title>
              <div>Occupation / Business Category</div>
            </template>
            <template v-slot:content>
              <div>
                <div class="text-[#595959] text-sm">
                  <div class="my-3">
                    <p>
                      The occupation and/or business category selected may not be correct. Please
                      check and amend if necessary before proceeding
                    </p>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    class="bg-[#95C11F] text-white font-medium p-2 px-6 mx-1 rounded-full"
                    @click.prevent="isOccupationBusinessOkModalCancel"
                  >
                    Amend
                  </button>
                  <button
                    class="border border-[#999999] text-[#999999] font-medium p-2 px-6 mx-1 rounded-full"
                    @click.prevent="isOccupationBusinessOkModalContinue"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </template>
          </Modal>
        </form>
      </div>
    </div>
  </div>
</template>
