import { computed, nextTick, reactive, watch } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { defineStore } from 'pinia'
import { datelocalised } from '@/utils/date'
import type {
  QuoteInputsSchemaType,
  ApiResponses,
  PolicyInterface,
  MetaInterface,
  QuoteStoreHelpersInterface
} from '@/stores/quote.schema'
import { fullQuoteHydrate } from '@/utils/hydrate'
import type {
  FullQuoteAddOnResponse,
  FullQuoteResponse,
  RecordWebEventRequest
} from '@/utils/api-types'
import { recordWebEvent } from '@/utils/api'
import { captureMessage as SentryCaptureMessage } from '@sentry/browser'

export const useQuoteStore = defineStore('quote', () => {
  const inputs: Partial<QuoteInputsSchemaType> = reactive({
    registration_number: '',
    registration_number_ui: '',
    type_approval: 'M1',
    journey_type: 'direct',
    selected_duration: 0,
    selected_duration_unit: undefined,
    cover_start: computed(() => {
      return datelocalised(inputs.cover_start_date_time).toISOString()
    }),
    cover_period_hours: computed(() => {
      return (inputs.selected_duration || 0) * (inputs?.selected_duration_unit === 'days' ? 24 : 1)
    }),
    cover_start_date: '',
    cover_start_hour: '',
    cover_start_minute: '',
    cover_start_date_time: undefined,
    first_name: '',
    last_name: '',
    dlntype: undefined,
    date_of_birth: undefined,
    address: '',
    postcode: '',
    email_address: '',
    email_address_confirmation: '',
    receive_marketing: undefined,
    gender: undefined,
    driving_licence_number_section_a: '',
    driving_licence_number_section_b: '',
    driving_licence_number_section_c: '',
    driving_licence_number_section_d: '',
    driving_licence_number: undefined,
    driving_licence_number_ui: undefined,
    driving_licence_held_for_years: undefined,
    occupation: '',
    business_type: '',
    phone_number: undefined,
    phone_number_ui: undefined,
    reason_for_cover: '',
    reason_for_cover_own_vehicle: '',
    lender_first_name: '',
    lender_last_name: '',
    accepted_declarations: false,
    registration_number_mot_valid_shown: '',
    is_occupation_business_ok_shown: false
  })

  const responsesInitial = {
    correlation_id: undefined,
    quote_expires_at: undefined,
    partner: {
      name: undefined,
      description: undefined,
      webquote_default_logo_path: undefined
    },
    vehicle: {
      type_approval: undefined,
      make: undefined,
      model: undefined
    },
    quickquote: {
      status: undefined,
      quote: {
        premium: '0',
        InceptDateTime: '',
        ExpiryDateTime: ''
      },
      errors: {},
      enumerations: {
        Occupation: [],
        Employer: [],
        CoverReason: [],
        CoverReason3: [],
        CoverReason2: []
      }
    },
    fullquote: {},
    buy: {},
    webquote: {},
    aggregator: {}
  }

  const responses: Partial<ApiResponses> = reactive({
    ...responsesInitial
  })

  const createPolicy = (initialValues: Partial<PolicyInterface> = {}): PolicyInterface => {
    const policyValue: PolicyInterface = reactive({
      currency: 'GBP',
      brand: 'Zixty',
      selectableBrands: undefined,
      addOns: {},
      ...initialValues,
      effectiveExcessValue: computed(() => {
        // get the starting excess
        let excess = 500
        if (policyValue?.brand === 'Zixtyplus') {
          excess = 400
        }

        // consider pe250
        if (!!policyValue?.addOns?.PE250) {
          excess = 250
        }

        // return what we have
        return excess
      }),
      selectedAddOnsIncludingMandatory: computed(() => {
        // get the selected addons
        const selectedAddOnsArray = Object.keys(policyValue?.addOns || {}).filter(
          (addOnKey) => !!policyValue.addOns[addOnKey]
        )

        let resp = selectedAddOnsArray.reduce(
          (acc, addOnKey) => {
            if (
              !!policyValue?.selectedBrand?.AddOns &&
              policyValue?.selectedBrand?.AddOns[addOnKey]
            ) {
              acc = {
                ...acc,
                [addOnKey]: policyValue.selectedBrand.AddOns[addOnKey]
              }
            }
            return acc
          },
          {} as Record<string, FullQuoteAddOnResponse>
        )

        // add the Mandatory ones
        if (
          !!policyValue?.selectedBrand?.AddOns &&
          typeof policyValue.selectedBrand.AddOns === 'object'
        ) {
          Object.keys(policyValue.selectedBrand.AddOns).forEach((addOnKey) => {
            const addOn =
              policyValue.selectedBrand?.AddOns && policyValue.selectedBrand?.AddOns[addOnKey]
                ? policyValue.selectedBrand?.AddOns[addOnKey]
                : undefined

            if (
              !!addOn &&
              !!addOn.Mandatory &&
              ((!!addOn.CanBeSwappedWith &&
                selectedAddOnsArray.indexOf(addOn.CanBeSwappedWith) === -1) ||
                !addOn.CanBeSwappedWith)
            ) {
              resp[addOnKey] = addOn
            }
          })
        }

        return resp
      }),
      selectedBrand: computed(() => {
        return policyValue.selectableBrands?.[policyValue.brand]
      }),
      total: computed(() => {
        // get the addOns the user has selected, this does not include Mandatory AddOns
        return Object.keys(policyValue?.addOns || {})
          .filter((addOnKey) => !!policyValue.addOns[addOnKey]) // ensure AddOn is selected
          .map((addOnKey) => policyValue.selectedBrand?.AddOns[addOnKey]) // get the AddOn
          .filter((addOn) => !!addOn && !addOn?.Mandatory) // remove Mandatory and blank AddOns
          .reduce(
            // get the total
            (total, addOn) => total + Number(addOn?.PremiumInclFee || 0),
            Number(policyValue?.selectedBrand?.PremiumInclFeeWithMandatoryAddOns || 0)
          )
      })
    })

    watch(
      () => responses?.fullquote,
      (newValue) => {
        policyValue.selectableBrands = newValue
          ? fullQuoteHydrate(newValue as FullQuoteResponse)?.quotes
          : newValue
      }
    )

    return policyValue
  }

  const policy = createPolicy()

  const meta: MetaInterface = reactive({
    isZixtyDotCom: computed(() => !!(window.location.host || '').match(/\.zixty\.com$/i)),
    isZixtyLocal: computed(() => !!(window.location.host || '').match(/\.local$/i))
  })

  const helpers: QuoteStoreHelpersInterface = {
    recordWebEvent: async (
      name: RecordWebEventRequest['name'],
      data: RecordWebEventRequest['data'] = {}
    ) => {
      return nextTick(() => {
        try {
          if (meta.isZixtyLocal) return

          if (!name) {
            SentryCaptureMessage('recordWebEvent: Event Name is missing.')
            return
          }

          if (!responses.correlation_id) {
            SentryCaptureMessage('recordWebEvent: Correlation ID is missing.')
            return
          }

          data = data || {}
          data.channel = data.channel || (responses.aggregator?.correlation_id ? 'agg' : 'web')

          recordWebEvent({
            correlation_id: responses.correlation_id || '',
            name: name,
            data: {
              ...data
            }
          })
        } catch (error) {
          //
        }
      })
    },
    isCoverStartTimeHistorical: () => {
      return Boolean(
        inputs?.cover_start &&
          inputs?.cover_start_hour &&
          inputs?.cover_start_minute &&
          datelocalised().unix() > datelocalised(inputs?.cover_start_date_time).unix()
      )
    },
    hasQuoteExpired: () => {
      return Boolean(
        responses?.quote_expires_at
          ? datelocalised().unix() > datelocalised(responses?.quote_expires_at).unix()
          : false
      )
    },
    restartQuote: () => {
      // reset the api responses
      Object.assign(responses, { ...responsesInitial })

      // reset the policy
      policy.currency = 'GBP'
      policy.brand = 'Zixty'
      policy.addOns = {}
    }
  }

  return {
    inputs: inputs,
    responses: responses,
    policy: policy,
    meta: meta,
    helpers: helpers
  }
})
