import type { FullQuoteAddOnResponse, FullQuoteResponse, FullQuoteResponseEntry } from './api-types'

export const fullQuoteHydrate = (data: FullQuoteResponse): FullQuoteResponse => {
  // exit early
  if (!data) {
    return data
  }

  // copy
  let copied = {
    ...data
  }

  // Zixty
  if (!!copied?.quotes?.Zixty?.AddOns?.BDC) {
    copied.quotes.Zixty.AddOns.BDC = {
      ...copied.quotes.Zixty.AddOns.BDC,
      UpgradeBoxTitle: 'Breakdown roadside, recovery, at home, and onward travel',
      Description:
        'Our highest level of breakdown cover. The reassurance of 24/7 help from our trusted network. Expert help is a phone call away.',
      Image: '/images/static/person-a.png'
    }
  }

  if (!!copied?.quotes?.Zixty?.AddOns?.PE250) {
    copied.quotes.Zixty.AddOns.PE250 = {
      ...copied.quotes.Zixty.AddOns.PE250,
      UpgradeBoxTitle: 'Protect your pocket with Excess Protect',
      Description:
        'Add Excess Protect to your policy and relax knowing that in the event of a fault claim, you can claim back £250 of your Excess.',
      Image: '/images/static/person-b.png'
    }
  }

  // Zixty Plus
  if (!!copied?.quotes?.Zixtyplus?.AddOns?.BDC) {
    copied.quotes.Zixtyplus.AddOns.BDC = {
      ...copied.quotes.Zixtyplus.AddOns.BDC,
      UpgradeBoxTitle: 'Upgrade to our highest level of breakdown cover',
      Description:
        'Add <strong>Home Assist</strong>, and <strong>Nationwide Recovery</strong> for our best cover. Get moving and stay moving with 24/7 help from our trusted network.',
      Image: '/images/static/person-a.png'
    }
  }

  if (!!copied?.quotes?.Zixtyplus?.AddOns?.PE250) {
    copied.quotes.Zixtyplus.AddOns.PE250 = {
      ...copied.quotes.Zixtyplus.AddOns.PE250,
      UpgradeBoxTitle: 'Boost your Excess protection to £250',
      Description:
        'Enjoy the reassurance of <strong>£250</strong> of Excess protection, and take some of the financial worry away from a fault claim.',
      Image: '/images/static/person-b.png'
    }
  }

  if (!!copied?.quotes?.Zixtyplus?.AddOns?.BR) {
    copied.quotes.Zixtyplus.AddOns.BR = {
      ...copied.quotes.Zixtyplus.AddOns.BR,
      Name: 'Breakdown cover'
    }
  }

  // pre-calculate the figures
  copied.quotes = Object.keys(copied.quotes || {}).reduce((plans, planKey) => {
    let fullQuoteResponseKey = planKey as keyof FullQuoteResponse['quotes']
    let item = copied.quotes[fullQuoteResponseKey]

    // calculate totals
    const totalWithMandatoryAddOnsValue = (
      obj: FullQuoteResponseEntry,
      keyName: 'Premium' | 'PremiumLessIPT' | 'PremiumInclFee' | 'IPT' | 'Fee'
    ) => {
      if (!obj || !obj.AddOns) return '0.00'

      return Object.keys(obj.AddOns)
        .reduce(
          (total: number, addOnKey) => {
            return !!obj?.AddOns && !!obj.AddOns[addOnKey]?.Mandatory
              ? total + Number(obj.AddOns[addOnKey][keyName])
              : total
          },
          Number(obj[keyName] || 0)
        )
        .toFixed(2)
    }

    item.PremiumWithMandatoryAddOns = totalWithMandatoryAddOnsValue(item, 'Premium')
    item.PremiumLessIPTWithMandatoryAddOns = totalWithMandatoryAddOnsValue(item, 'PremiumLessIPT')
    item.PremiumInclFeeWithMandatoryAddOns = totalWithMandatoryAddOnsValue(item, 'PremiumInclFee')
    item.IPTWithMandatoryAddOns = totalWithMandatoryAddOnsValue(item, 'IPT')
    item.FeeWithMandatoryAddOns = totalWithMandatoryAddOnsValue(item, 'Fee')

    // calculate the difference for the addOns
    const valDiffAddOns = (
      addOnA: FullQuoteAddOnResponse,
      addOnB: FullQuoteAddOnResponse,
      keyName: 'Premium' | 'PremiumLessIPT' | 'PremiumInclFee' | 'IPT' | 'Fee'
    ) => {
      return (Number(addOnA[keyName] || 0) - Number(addOnB[keyName] || 0)).toFixed(2)
    }

    if (item?.AddOns) {
      item.AddOns = Object.keys(item.AddOns).reduce((acc, addOnKey) => {
        const curAddOn = item.AddOns[addOnKey]
        const swpAddOn = item.AddOns[`${curAddOn?.CanBeSwappedWith}`]

        if (!!curAddOn && !!swpAddOn && !curAddOn.Mandatory && !!curAddOn?.CanBeSwappedWith) {
          item.AddOns[addOnKey].Premium = valDiffAddOns(curAddOn, swpAddOn, 'Premium')
          item.AddOns[addOnKey].PremiumLessIPT = valDiffAddOns(curAddOn, swpAddOn, 'PremiumLessIPT')
          item.AddOns[addOnKey].PremiumInclFee = valDiffAddOns(curAddOn, swpAddOn, 'PremiumInclFee')
          item.AddOns[addOnKey].IPT = valDiffAddOns(curAddOn, swpAddOn, 'IPT')
          item.AddOns[addOnKey].Fee = valDiffAddOns(curAddOn, swpAddOn, 'Fee')
        }

        return acc
      }, item.AddOns)
    }

    return {
      ...plans,
      [fullQuoteResponseKey]: {
        ...item
      }
    }
  }, copied.quotes)

  // send back what we have
  return copied
}
