<script setup lang="ts">
import { useToggle } from '@vueuse/core'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'

const emit = defineEmits(['close'])

export interface Props {
  size?: string
  isClosable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'max-w-3xl',
  isClosable: true
})

defineExpose({
  open: openModal,
  close: closeModal
})

const [isOpen] = useToggle()

function closeModal() {
  if (!!props?.isClosable && isOpen.value) {
    isOpen.value = false
    emit('close')
  }
}
function openModal() {
  isOpen.value = true
}
</script>

<template>
  <button type="button" @click="openModal">
    <slot name="trigger" />
  </button>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex justify-center text-center min-h-full items-end pt-4 md:items-center md:p-4"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full transform overflow-hidden rounded-t-2xl md:rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              :class="{
                [props.size]: true
              }"
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                <div class="flex items-center justify-between">
                  <div>
                    <slot name="title" />
                  </div>
                  <div>
                    <button
                      type="button"
                      class="z-50 p-2 border-none focus:ring-0 focus-visible:outline-none"
                      :class="{
                        'cursor-pointer hover:text-[#95C11F]': isClosable,
                        'opacity-20': !isClosable
                      }"
                      @click.prevent="closeModal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </DialogTitle>
              <slot name="content" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
