<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Fuse from 'fuse.js'
import VueMultiselect from 'vue-multiselect'
import { delay } from '@/utils/helpers'

export type PropsOptions = {
  label: string
  value: string
  selected?: boolean
}

export interface Props {
  id: string
  class: string
  name: string
  placeholder: string
  modelValue?: string
  options: PropsOptions[]
  noResultMessage?: string
}

const props = withDefaults(defineProps<Props>(), {
  noResultMessage: 'No elements found. Consider changing the search query.'
})

const $emit = defineEmits(['update:modelValue'])

const selectedOption = (props?.options || [])
  .filter((item) => item?.value === props?.modelValue)
  .pop()

const elRef = ref()
const tempModelValue = ref<PropsOptions | undefined>(selectedOption)
const tempOptions = ref<PropsOptions[]>(props.options)

const fuse = new Fuse(props.options, {
  includeScore: true,
  distance: 1,
  threshold: 0.1,
  ignoreLocation: true,
  keys: ['label']
})

const hjClassName = 'data-hj-allow'

const getInputOrBlankInputElement = (): HTMLElement => {
  return document.getElementById(elRef.value?.id) || document.createElement('input')
}

const hjTagInputElement = () => {
  let tmpEl = getInputOrBlankInputElement()
  if (!tmpEl.classList.contains(hjClassName)) {
    tmpEl.classList.add(hjClassName)
  }
}

const hjTagSelectedDropdownItem = () => {
  delay(75).then(() => {
    const tmpEl = getInputOrBlankInputElement().parentNode?.querySelector('.multiselect__single')
    if (!!tmpEl && !tmpEl.classList.contains(hjClassName)) {
      tmpEl.classList.add(hjClassName)
    }
  })
}

const onSelect = (selectedOption: PropsOptions) => {
  if (!!selectedOption?.value) {
    $emit('update:modelValue', selectedOption.value)
    hjTagSelectedDropdownItem()
  }
}

const onSearchChange = async (query: string) => {
  // only when this is open
  if (!elRef.value?.isOpen) return

  // default options
  if (!query) {
    tempOptions.value = props.options
    return
  }

  // search
  const fuseSearchResults = fuse.search(query)

  const searchResultOptions = fuseSearchResults.reduce((acc, item) => {
    return [...acc, { ...item.item }]
  }, [] as PropsOptions[])

  tempOptions.value = searchResultOptions
}

onMounted(() => {
  if (!!elRef.value && props?.class && !!(props.class || '').match(/data-hj-allow/)) {
    hjTagInputElement()
    hjTagSelectedDropdownItem()
  }
})
</script>

<template>
  <VueMultiselect
    ref="elRef"
    :id="props.id"
    :class="[props.class]"
    :name="props.name"
    :placeholder="props.placeholder"
    v-model="tempModelValue"
    :options="tempOptions"
    @search-change="onSearchChange"
    @select="onSelect"
    :close-on-select="true"
    :clear-on-select="false"
    :multiple="false"
    :searchable="true"
    label="label"
    track-by="value"
    :options-limit="250000"
    :show-labels="false"
    :internal-search="false"
    :open-direction="'bottom'"
    :allow-empty="false"
  >
    <template v-slot:noResult> {{ props.noResultMessage }} </template>
  </VueMultiselect>
</template>
